import React from "react";
import { useTranslation } from "react-i18next";
import { List, ListItem, ListItemText, Divider, Chip} from "@mui/material";

const NetworkAdapterShow = ({
	name,
	networkAdapters,
	activeNetworkAdapters,
}) => {
	const { t } = useTranslation();
	const access = "get_set";
	return (
		<>
			<List>
				{networkAdapters.map(({ name }) => (
					<>
						<ListItem>
							<ListItemText primary={name} />
							{activeNetworkAdapters.find((item) => item.name === name) ? (
								<Chip label={t("active")} color="success" />
							) : (
								""
							)}
						</ListItem>
						<Divider />{" "}
					</>
				))}
			</List>
		</>
	);
};

export default NetworkAdapterShow;
